@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  padding: 0;
  margin: 0;
}

label {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 5px;
  font-size: 14px;
  width: 100%;
}

input {
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  width: 100%;
}

textarea {
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  width: 100%;
}

.active-nav {
  /* background: #22BDFF; */
  border-bottom: 2px solid #22BDFF;
  /* height: 1px; */
  /* width: 10px; */
}

.shadow-center {
  box-shadow: 0px 0px 10px #00000046;
}

.divider {
  border: 1px solid #d3d3d3;
  width: full;
}

@media only screen and (max-width: 600px) {
  .navbar-mobile {
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0px 0px 0px 3px #3333;
    left: 0;
    top: 0;
    margin-top: 50px;
    margin-left: 20px;
  }
}

/* HTML: <div class="loader"></div> */
.button-loader {
  width: 30px;
  padding: 6px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #25b09b;
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {to{transform: rotate(1turn)}}